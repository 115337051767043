<template>
  <div class="customers-page">

    <Alerta
      type="info"
      titulo="Novidade!"
      mensagem="Nova área para visualização de dados dos clientes."
      idMemoria="dicaCustomersNew2"
    />

    <div class="box-filter">
      <div></div>
      <FilterDatepicker @getDataSelect="setDataFilter" />
    </div>

    <div v-if="tab == 'principal'">

      <b-row>
        <b-col md="12" lg="12" sm="12">
          <div class="primary primary--mb">
            <!-- LTV -->
            <div class="box">
              <div class="panel w-100">
                <div class="panel__content">
                  <div class="panel__header box__header">
                    <h2 class="panel__title panel__title--minor">
                      Cálculo<br/>
                      <b>LTV</b>
                    </h2>
                  </div>
                  <div class="panel__body">
                    <div class="panel__numbers">
                      <div class="panel__numbers__data --yesterday">
                        <h3 class="panel__numbers__data__label">COMPRAS POR ANO</h3>
                        <h4 class="panel__numbers__data__number" v-if="ltv.averagePurchasesPerYear">
                          <span class="number--normal">{{ ltv.averagePurchasesPerYear | numberToRealWitoutSymbol }}</span>
                        </h4>
                        <h4 class="panel__numbers__data__number" v-else>
                          <span class="number--normal">...</span>
                        </h4>
                      </div>
                      <div class="panel__numbers__data --today">
                        <h3 class="panel__numbers__data__label">
                          TICKET MÉDIO<br/>
                          <span v-if="start || end">({{ start | filterData }} a {{ end | filterData }})</span>
                        </h3>
                        <h4 class="panel__numbers__data__number" v-if="ltv.ticket_medio">
                          <span class="number--normal">{{ ltv.ticket_medio | numberToReal }}</span>
                        </h4>
                        <h4 class="panel__numbers__data__number" v-else>
                          <span class="number--normal">...</span>
                        </h4>
                      </div>
                      <div class="panel__numbers__data --month">
                        <h3 class="panel__numbers__data__label">LTV</h3>
                        <h4 class="panel__numbers__data__number" v-if="ltv.ltv">
                          <span class="number--normal">{{ ltv.ltv | numberToReal }} 
                            <i
                            title="LTV calculado com base no Ticket Médio do período selecionado"
                            v-b-tooltip.hover.right>
                              <IconCheck icon-color="#73cb53"></IconCheck>
                            </i>
                          </span>
                        </h4>
                        <h4 class="panel__numbers__data__number" v-else>
                          <span class="number--normal">...</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- LTV -->
          </div>
        </b-col>
      </b-row>

      <b-row>
        
        <b-col md="6" lg="4" sm="12">
          <div class="primary primary--mb">

            <!-- Clientes Quantidade -->
            <div class="box">
              <div class="panel w-100">
                <div class="panel__content">
                  <div class="panel__header">
                    <h2 class="panel__title panel__title--minor">
                      Clientes<br/>
                      <b>Quantidade</b>
                    </h2>
                  </div>
                  <div class="panel__body">
                    <div class="panel__numbers">
                      <div class="panel__numbers__data --today">
                        <h3 class="panel__numbers__data__label">RECORRENTE</h3>
                        <h4 class="panel__numbers__data__number" v-if="recurrent.recurrent">
                          <span class="number--normal">{{ recurrent.recurrent[0] }}</span>
                          <span class="number--small">({{ recurrent.recurrent[1] }}%)</span>
                        </h4>
                        <h4 class="panel__numbers__data__number" v-else>
                          <span class="number--normal">...</span>
                        </h4>
                      </div>

                      <div class="panel__numbers__data --month">
                        <h3 class="panel__numbers__data__label">TOTAL</h3>
                        <h4 class="panel__numbers__data__number" v-if="recurrent.all">
                          <span class="number--normal">{{ recurrent.all[0] }}</span>
                        </h4>
                        <h4 class="panel__numbers__data__number" v-else>
                          <span class="number--normal">...</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Clientes Quantidade -->

            <!-- Clientes Ticket Médio -->
            <div class="box">
              <div class="panel w-100">
                <div class="panel__content">
                  <div class="panel__header">
                    <h2 class="panel__title panel__title--minor">
                      Pedidos<br/>
                      <b>Ticket Médio</b>
                    </h2>
                  </div>
                  <div class="panel__body">
                    <div class="panel__numbers">
                      <div class="panel__numbers__data --today">
                        <h3 class="panel__numbers__data__label">RECORRENTE</h3>
                        <h4 class="panel__numbers__data__number" v-if="ticketMedio.recurrent">
                          <span class="number--normal">{{ ticketMedio.recurrent[0] | numberToReal }}</span>
                          <!-- <span class="number--small">({{ ticketMedio.recurrent[1] }}%)</span> -->
                        </h4>
                        <h4 class="panel__numbers__data__number" v-else>
                          <span class="number--normal">...</span>
                        </h4>
                      </div>

                      <div class="panel__numbers__data --month">
                        <h3 class="panel__numbers__data__label">1ª COMPRA</h3>
                        <h4 class="panel__numbers__data__number" v-if="ticketMedio.first">
                          <span class="number--normal">{{ ticketMedio.first[0] | numberToReal }}</span>
                          <!-- <span class="number--small">({{ ticketMedio.first[1] }}%)</span> -->
                        </h4>
                        <h4 class="panel__numbers__data__number" v-else>
                          <span class="number--normal">...</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Clientes Ticket Médio -->
          </div>

        </b-col>

        <b-col md="6" lg="4" sm="12">
          <div class="primary">
            <!-- Pedidos Quantidade -->
            <div class="box">
              <div class="panel w-100">
                <div class="panel__content">
                  <div class="panel__header">
                    <h2 class="panel__title panel__title--minor">
                      Pedidos<br/>
                      <b>Quantidade</b>
                    </h2>
                  </div>
                  <div class="panel__body">
                    <div class="panel__numbers">
                      <div class="panel__numbers__data --today">
                        <h3 class="panel__numbers__data__label">RECORRENTE</h3>
                        <h4 class="panel__numbers__data__number" v-if="ordersTotal.recurrent">
                          <span class="number--normal">{{ ordersTotal.recurrent[0] }}</span>
                          <span class="number--small">({{ ordersTotal.recurrent[1] }}%)</span>
                        </h4>
                        <h4 class="panel__numbers__data__number" v-else>
                          <span class="number--normal">...</span>
                        </h4>
                      </div>

                      <div class="panel__numbers__data --month">
                        <h3 class="panel__numbers__data__label">1ª COMPRA</h3>
                        <h4 class="panel__numbers__data__number" v-if="ordersTotal.first">
                          <span class="number--normal">{{ ordersTotal.first[0] }}</span>
                          <span class="number--small">({{ ordersTotal.first[1] }}%)</span>
                        </h4>
                        <h4 class="panel__numbers__data__number" v-else>
                          <span class="number--normal">...</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Pedidos Quantidade -->

            <!-- Pedidos Faturamento -->
            <div class="box">
              <div class="panel w-100">
                <div class="panel__content">
                  <div class="panel__header">
                    <h2 class="panel__title panel__title--minor">
                      Pedidos<br/>
                      <b>Receita</b>
                    </h2>
                  </div>
                  <div class="panel__body">
                    <div class="panel__numbers">
                      <div class="panel__numbers__data --today">
                        <h3 class="panel__numbers__data__label">RECORRENTE</h3>
                        <h4 class="panel__numbers__data__number" v-if="ordersBilling.recurrent">
                          <span class="number--normal">{{ ordersBilling.recurrent[0] | numberToReal }}</span>
                          <span class="number--small">({{ ordersBilling.recurrent[1] }}%)</span>
                        </h4>
                        <h4 class="panel__numbers__data__number" v-else>
                          <span class="number--normal">...</span>
                        </h4>
                      </div>

                      <div class="panel__numbers__data --month">
                        <h3 class="panel__numbers__data__label">1ª COMPRA</h3>
                        <h4 class="panel__numbers__data__number" v-if="ordersBilling.first">
                          <span class="number--normal">{{ ordersBilling.first[0] | numberToReal }}</span>
                          <span class="number--small">({{ ordersBilling.first[1] }}%)</span>
                        </h4>
                        <h4 class="panel__numbers__data__number" v-else>
                          <span class="number--normal">...</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Pedidos Faturamento -->
          </div>
        </b-col>

        <b-col md="6" lg="4" sm="12">
          <div class="primary">
            <div class="box table">
              <div class="box__header">
                <HeaderGraphic :data="loadTable" />
                <a v-if="loadTable.items.length > 0" class="btn-download" @click="downloadCSV()" download
                  >Download CSV</a
                >
              </div>

              <div class="painel">
                <div v-if="loaded"> 
                  <b-table :items="loadTable.items" v-if="loadTable.items.length > 0">

                    <template #cell(nome)="data">
                      <a :href="`/customers/${data.item.Nome.id}/details`" class="h6 cor-escura">
                        <IconStar :active="(data.item.Nome.starred) ? data.item.Nome.starred : false" />
                        {{ data.item.Nome.text }}
                        <small v-if="data.item.Nome.storeUserId" class="cor-media">#{{ data.item.Nome.storeUserId }}</small>
                      </a>
                    </template>
                  </b-table>
                  <p v-else class="mb-20">Sem dados.</p>
                </div>
                <p v-else class="mb-20">...</p>

                <a class="btn-link pl-0" href="/customers/list">Ver todos os clientes &rarr;</a>

              </div>
            </div>
          </div>
        </b-col>

      </b-row>
      
    </div>

  </div>
</template>

<script>
import { mapState } from "vuex";
import Alerta from "@/components/alerta/Alerta.vue";
import IconStar from "@/components/icones/IconStar.vue";
import IconCheck from "@/components/icones/iconCheck.vue";
import FilterDatepicker from "@/components/filter-datepicker/FilterDatepicker.vue";
import HeaderGraphic from "@/components/header-graphic/HeaderGraphic.vue";
import { numberToRealWitoutSymbol, numberToReal } from "@/utils/money";
import { filterData } from "@/utils/date";
import { 
  fetchOrdersTotal, 
  fetchOrdersBilling, 
  fetchRecurrent,
  fetchTicketMedio,
  fetchRepurchase,
  getRepurchaseCSV,
  fetchLtv,
  getLtvCSV,
  fetchCustomers,
  fetchCustomersCSV,
} from "@/api/customers";

export default {
  filters: {
    numberToRealWitoutSymbol,
    numberToReal,
    filterData,
  },
  components: {
    HeaderGraphic,
    FilterDatepicker,
    Alerta,
    IconStar,
    IconCheck,
  },
  data() {
    return {
      loaded: false,
      tab: 'principal',
      tableData: {},
      perPage: 3,
      currentPage: 1,
      start: null,
      end: null,
      chartData: {},
    };
  },
  computed: {
    ...mapState({
      ordersTotal: (state) => state.customers.ordersTotal,
      ordersBilling: (state) => state.customers.ordersBilling,
      list: (state) => state.customers.list,
      lastPage: (state) => state.customers.list_last_page,
      recurrent: (state) => state.customers.recurrent,
      ticketMedio: (state) => state.customers.ticketMedio,
      repurchase: (state) => state.customers.repurchase,
      ltv: (state) => state.customers.ltv,
    }),
    loadTable() {
      const items = [];
      if (this.list.data && this.list.data.length > 0) {
        this.list.data.map((line, pos) => {
          items.push(
            {
              "#": pos+1,
              Nome: {
                id: btoa(line.id),
                storeUserId: line.storeUserId,
                text: `${line.firstName} ${line.lastName}`,
                starred: line.starred
              },
              "Qtde. de pedidos": line.ordersCount,
              Receita: numberToReal(line.ordersRevenue),
            }
          );
        });
      }

      let data = {
        title: {
          top: "Clientes",
          name: `Top ${this.perPage}`,
        },
        items,
      };

      return data;
    },
  },
  mounted() {
    this.loadTab(this.tab);
  },
  methods: {
    fetchOrdersTotal,
    fetchOrdersBilling,
    fetchRecurrent,
    fetchTicketMedio,
    fetchRepurchase,
    getRepurchaseCSV,
    fetchLtv,
    getLtvCSV,
    fetchCustomers,
    fetchCustomersCSV,
    setTab(type) {
      this.tab = type;
    },
    setDataFilter({ start, end }) {
      this.loaded = false;
      (this.start = start), (this.end = end);
      this.loadTab(this.tab);
    },
    downloadCSV() {
      this.fetchCustomersCSV(this.start, this.end);
    },
    downloadCSVCustomers() {
      this.getRepurchaseCSV(this.start, this.end);
    },
    downloadCSVLtv() {
      this.getLtvCSV(this.start, this.end);
    },
    loadGraphicBar(value) {
      // console.log('loadGraphicBar new', value)
      const data = [];

      if (value.data) {
        value.data.map(line => {
          data.push({
            name: `Clientes com ${line.pedidos} pedido${(line.pedidos > 1) ? 's' : ''}`,
            valor_vendido: line.clientes
          });
        });
      }
      // console.log('loadGraphicBar data', data)

      return (this.chartData = data);
    },
    loadPrincipal() {
      this.fetchOrdersTotal(this.start, this.end);
      this.fetchOrdersBilling(this.start, this.end);
      this.fetchCustomers(this.start, this.end, this.perPage, this.currentPage);
      this.fetchRecurrent(this.start, this.end);
      this.fetchTicketMedio(this.start, this.end);
      this.fetchLtv(this.start, this.end);
    },
    loadLtv() {
      this.fetchRepurchase(this.start, this.end);
    },
    loadGeografico() {
    },
    loadTab(tab) {
      switch (tab) {
        case 'principal':
          this.loadPrincipal();
          break;

        case 'ltv':
          this.loadLtv();
          break;

        case 'geografico':
          this.loadGeografico();
          break;
      
        default:
          break;
      }
    }
  },
  watch: {
    list() {
      this.loaded = true;
    },
    repurchase(newData) {
      if (newData) this.loadGraphicBar((newData.repurchase) ? newData.repurchase : []);
    },
    currentPage(newPage) {
      this.currentPage = newPage;
      this.fetchCustomers( this.start, this.end, this.perPage, this.currentPage);
    },
    tab(newTab) {
      this.loadTab(newTab);
    },
  },
};
</script>

<style lang="scss">
::v-deep {
  .multiselect__placeholder {
    margin-top: 3px;
  }
}
.customers-page {
  .empty {
    text-align: center;
    width: 100%;
    font-size: 16px;
    height: 100%;
    padding: 90px 0;
    display: flex;
    color: $red !important;
    align-items: center;
    justify-content: center;
  }
  .primary {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    &.primary--mb {
      margin-bottom: 30px;
    }
    .btn-download {
      position: relative;
      z-index: 1;
    }
  }
  .box-filter {
    display: flex;
    align-items: center;
    // justify-content: flex-end;
    justify-content: space-between;
    margin-bottom: 15px;
    gap: 16px;
    &__tabs {
      display: flex;
      border-bottom: 1px solid #bababa;
      @media only screen and (max-width: 991px) {
        margin-top: 20px;
      }
    }
    &__tabs-item {
      margin-right: 20px;
      text-transform: uppercase;
      font-size: 14px;
      position: relative;
      padding-bottom: 10px;
      cursor: pointer;
      @media only screen and (max-width: 991px) {
        font-size: 12px;
        text-align: center;
      }
      &:hover {
        color: $red;
      }
      &:last-child {
        margin-right: 0;
      }
      &.--active {
        position: relative;

        &::before {
          content: "";
          width: 100%;
          height: 2px;
          position: absolute;
          bottom: -1px;
          background: $red;
          @media only screen and (max-width: 991px) {
            left: 0;
          }
        }
      }
    }
  }

  .box {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    background-color: #fff;
    padding: 18px;
    box-shadow: 0 3px 6px #c3d0e029;
    border-radius: 20px;
    @media screen and (max-width: 991px) {
      gap: 20px;
      padding: 20px 15px;
    }
    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
    &--chart {
      flex-direction: column;
      height: 400px;
      .header-graphic {
        flex-direction: column;
        margin-bottom: 10px;
      }
    }
    &__header {
      display: flex;
      justify-content: space-between;
    }
    .painel {
      position: relative;
      height: 300px;
      display: block;
      .table {
        td[aria-colindex="0"],
        td[aria-colindex="1"] {
          font-weight: bold;
        }
        th[aria-colindex="3"],
        td[aria-colindex="3"],
        th[aria-colindex="4"],
        td[aria-colindex="4"] {
          white-space: nowrap;
          text-align: right;
        }
      }
    }
    &.table {
      margin: 0;
      flex-direction: column;
      height: auto;
      &:after {
        content: "";
        display: block;
      }
      .painel {
        height: auto; 
      }
    }
    .panel {
      &__content {
        padding: 0;
        background: none;
        box-shadow: none;
      }
      &__header {
        @media screen and (max-width: 991px) {
          min-height: 1010px;
        }
        @media screen and (max-width: 600px) {
          min-height: 60px;
        }
      }
    }

    .number {
      &--medium {
        font-size: 50px;
        @media screen and (max-width: 991px) {
          font-size: 25px;
        }
        @media screen and (max-width: 600px) {
          font-size: 35px;
        }
      }
    }
  }

  .v-table {
    display: inline-block;
    width: 100%;
    margin-top: 35px;
    &__row {
      display: grid;
      grid-template-columns: 0.1fr 1.3fr 0.3fr 0.5fr;
      align-items: center;
      margin-top: 0.9rem;
      gap: 15px;
      padding-bottom: 18px;
      border-bottom: 1px solid #e6e6e6;
      font-weight: bold;
      color: #2c2c2c;
      font-size: 16px;
      &:last-child {
        border-bottom: none;
      }
    }
    &__cell {
      &:last-child {
        text-align: right;
      }
    }
  }
}
</style>
